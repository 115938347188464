import React, { Component } from 'react';
import { Navbar, Nav, Button, Form, Modal, FormControl } from 'react-bootstrap';
import logo from '../../images/logo.png';
import { NavLink, Link } from 'react-router-dom';

class MainHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: '',
      password: '',
      message: '',
      token: '',
      logText:'Login',
      select_language: 'en',
      customer_name: '',
      showLoginModal:false
      }

    let search = window.location.search;
    let params = new URLSearchParams(search);
    this.token = params.get('token');
    if (typeof (this.token) == 'undefined' || this.token == null) { this.token = ""; }
    else{ 
      let user = {"auth_token":this.token };
      localStorage.setItem('user', JSON.stringify(user)); 
    }
    let Lang = localStorage.getItem('Lang');
    if(!Lang){
      localStorage.setItem('Lang', this.state.select_language);
    } else {
      
      console.log(Lang);
    }

    }

    selectOptionHandler = (e) => {
      this.setState({ [e.target.id]: e.target.value });
      setTimeout(() => { this.setLanguage(); }, 100);
    }
    setLanguage = ()=>{
      localStorage.setItem('Lang', this.state.select_language);
      setTimeout(() => { document.location.reload(); }, 100);      
    }

  getContact = (e) => {
    e.preventDefault();
    var getId = document.getElementById('ContactUs');
    window.scrollBy(0, getId.offsetTop + 100);
  }
  logOut = (e) => {
    e.preventDefault();
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.auth_token) {
      localStorage.removeItem('user');
      document.location.reload();
    } else {
      this.setState({ showLoginModal: true });
    }
  }

  onHideLogin = ()=>{
    this.setState({ showLoginModal: false });
  }
  

  sticky = {
    sticky_after: 200,
    init: function() {
      this.header = document.getElementsByTagName("header")[0];
      this.clone = this.header.cloneNode(true);
      this.clone.classList.add("clone");
      //this.header.insertBefore(this.clone);
      this.scroll();
      this.events();
    },
  
    scroll: function() {
      if(window.scrollY > this.sticky_after) {
        this.header.classList.remove("bgImageHeader");
        this.header.classList.add("sticky");
        this.header.classList.add("slideInDown");
      }
      else {
        this.header.classList.remove("sticky");
        this.header.classList.remove("slideInDown");
        if (document.querySelector('.banner') == null) {
          this.header.classList.add('bgImageHeader')
        }
        
      }
    },
  
    events: function() {
      window.addEventListener("scroll", this.scroll.bind(this));
    }
  };
  
  

  componentDidMount = async ()=>{
    window.scroll = () => {
      if(this.scrollY > 0){
        console.log('y');
      }else{
        console.log('x');
      }
    }
    document.addEventListener("DOMContentLoaded", this.sticky.init.bind(this.sticky));
    let user = JSON.parse(localStorage.getItem('user'));

    if (user && user.auth_token) {
      this.setState({ showLoginModal: false });
      this.setState({ customer_name: user.fname });
      this.setState({ logText: 'Logout' });
      console.log(user.fname);
    } else {
      
      //this.setState({ showLoginModal: true });
    }
    let Lang = localStorage.getItem('Lang');
    if(!Lang){
      localStorage.setItem('Lang', this.state.select_language);
      
    } else {
      this.setState({select_language:Lang});
     // document.getElementById('select_language').value = Lang;      
    }
   // const activityList = await fetch('https://app.join.com.kw/api/v1/getActiveActivityList');
   // const getJson = await activityList.json();
    //this.setState({ options: getJson });
  }
  login = (event)=> {
    event.preventDefault();
    const data = new FormData(event.target);
    fetch('https://app.join.com.kw/api/v1/logIn', {
      method: 'POST',
      headers: {
        //'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Lang': localStorage.getItem('Lang'),
      },
      body: data,
    }).then(results => {
      return results.json();
    }).then(data => {
      console.log(data.message);
      if (data.code === 1) {
        document.getElementById('loginmsg').innerHTML = '<div class="alert alert-success" role="alert">' + data.message + '</div>';
        localStorage.setItem('user', JSON.stringify(data.payload));
        
        this.setState({ customer_name: data.payload.fname });
        this.setState({ logText: 'Logout' });
        const token = data.payload.auth_token;
        setTimeout(()=> { this.setState({ showLoginModal: false }); document.location.reload(); }, 2100);
        console.log(data.payload);
      } else {
        document.getElementById('loginmsg').innerHTML = '<div class="alert alert-danger" role="alert">' + data.message + '</div>';
      }
      setTimeout(()=> { document.getElementById('loginmsg').innerHTML = ''; }, 2200);
    });
  }


  render() {
    return (
      <header id="main_header" className="header_area animated">
        <Navbar expand="md" className="menu_area">
          
            {(()=>{
              if(this.state.select_language=='en'){
                return (
                  <div className="container-fluid">
                    <Link to="/" className="navbar-brand">
              <img src={logo} alt="logo" className="img-fluid" />
            </Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto" id="nav">
                <a className="nav-link" href="https://join.com.kw/">Home</a>
                <a className="nav-link" href="https://join.com.kw/#about">About</a>
                <a className="nav-link" href="https://join.com.kw/#features">Features</a>
                <a className="nav-link" href="https://join.com.kw/#screenshot">Sneak Peak</a>
                {/* <NavLink className="nav-link" to="/activites">Activites</NavLink> */}
                <NavLink className="nav-link" to="/">Book Now</NavLink>
                <NavLink className="nav-link" onClick={this.getContact} to="#">Contact Us</NavLink>
                <Button className="nav-link nav-link1 margin10" onClick={this.logOut}>{this.state.logText}</Button>
                {this.state.customer_name ? <Button className="nav-link nav-link1 margin10">{this.state.customer_name}</Button> : ''}
                
              </Nav>
            </Navbar.Collapse>
            <div className="aa">
            <FormControl  id="select_language" as="select" defaultValue={this.state.select_language} onChange={this.selectOptionHandler}>
                  <option value="en" key='0'>English</option>
                  <option value="ar" key='1'>Arabic</option>
                </FormControl>
                </div>

                  </div>
                );
              } else {
                return (
                  <div className="container-fluid">
                <div className="aa">
            <FormControl  id="select_language" as="select" defaultValue={this.state.select_language} onChange={this.selectOptionHandler}>
                  <option value="en" key='0'>English</option>
                  <option value="ar" key='1'>Arabic</option>
                </FormControl>
                </div>    
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse className="" id="basic-navbar-nav">
              <Nav className="" id="nav">
                
                
                {/* <NavLink className="nav-link" to="/activites">Activites</NavLink> */}
                
                
                
                {this.state.customer_name ? <Button className="nav-link nav-link1 margin10">{this.state.customer_name}</Button> : ''}
                <Button className="nav-link nav-link1 margin10" onClick={this.logOut}>{this.state.logText}</Button>
                <NavLink className="nav-link" onClick={this.getContact} to="#">واصل معنا</NavLink>
                <a className="nav-link" href="https://join.com.kw/#screenshot">مقتطفات سريعة</a>
                <a className="nav-link" href="https://join.com.kw/#features">المميزات</a>
                <a className="nav-link" href="https://join.com.kw/#about">النادي</a>
                <a className="nav-link" href="https://join.com.kw">الصفحة الرئيسية</a>
              </Nav>
            </Navbar.Collapse>
            
                <Link to="/" className="navbar-brand">
              <img src={logo} alt="logo" className="img-fluid" />
            </Link>
                  </div>
                );
              }
            })()}
            
          
        </Navbar>
        <div id="flshMsg"></div>
        <div>
      <Modal show={this.state.showLoginModal} onHide={this.onHideLogin}>
      <Form action="#" className="contact_input_area" onSubmit={this.login}>
                
        <Modal.Header closeButton>
          <Modal.Title className="m-0">Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="loginmsg"></div>
        <FormControl type="text" className="form-group" id="fname" name="mobile" placeholder="Your Mobile.." value={this.state.mobile}
                  onChange={e => this.setState({ mobile: e.target.value })} />
                <FormControl type="password" className="form-control form-group" id="email" name="password" placeholder="Your password" value={this.state.password}
                  onChange={e => this.setState({ password: e.target.value })} />
          
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          
        <FormControl type="submit" className="btn submit-btn" value="Login" />
        </Modal.Footer>
        </Form>
      </Modal>
    </div>
      </header>
      
    );
  }
}

export default MainHeader;
