import React, { Component } from 'react';
import { Button, Modal, FormControl } from 'react-bootstrap';


class BookNow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      book_options: [
        {
          select: [
            {
              name: 'Name',
              options: [
                { option: 1 },
                { option: 2 },
                { option: 3 }
              ]
            },
            {
              name: 'Age',
              options: [
                { option: 1 },
                { option: 2 },
                { option: 3 }
              ]
            },
            {
              name: 'Gender',
              options: [
                { option: 'Male' },
                { option: 'Female' },
                { option: 'Others' }
              ]
            }
          ]
        }
      ],
      auth_token: '',
      getActivityById: [],
      dateTime: [],
      activityDetails: [],
      BookingData: [],
      child_data: [],
      child: [],
      from_d: '',
      to_d: '',
      days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thrusday', 'Friday', 'Saturday'],
      allDates: [],
      times: [],
      select_location: '',
      select_time: '',
      select_date: '',
      input_check_box: [],
      allActivityData:false,
      ActivityData: [],
      getChildData: [],
      BookingSession: [],
      activityId: this.props.activityId,
      showBookModal: false,
      promoList:false,
      promocode: false,
      promocodeDetails: false,
      mrp:0,
      discount:0,
      total: 0,
    }
    this.addChild = this.addChild.bind(this);
    this.removeChild = this.removeChild.bind(this);
    this.getDateFrom_to = this.getDateFrom_to.bind(this);
    this.createBooking = this.createBooking.bind(this);
    this.selectOptionHandler = this.selectOptionHandler.bind(this);
    this.getBookingData = this.getBookingData.bind(this);
    this.selectLocation = this.selectLocation.bind(this);
  }

  addChild = () => {
    const child = this.state.book_options;
    const newChild = child.push({
      select: [
        {
          name: 'Name',
          options: [
            { option: 1 },
            { option: 2 },
            { option: 3 }
          ]
        },
        {
          name: 'Age',
          options: [
            { option: 1 },
            { option: 2 },
            { option: 3 }
          ]
        },
        {
          name: 'Gender',
          options: [
            { option: 'Male' },
            { option: 'Female' },
            { option: 'Others' }
          ]
        }
      ]
    });
    this.setState({ newChild });
  }

  removeChild = (index) => {
    const r_child = this.state.book_options.splice(index, 1);
    this.setState({ r_child });
  }

  getDateFrom_to = () => {
    if (this.state.dateTime.length !== 0 || this.state.dateTime.length > 0) {
      var from = new Date(this.state.from_d);
      var to = new Date(this.state.to_d);
      var f = from.getFullYear();
      var m = from.getMonth();
      var y = [];
      for (var i = from.getMonth() + 1; i <= 12 + to.getMonth(); i++) {
        var aa = new Date(f, i, 0).getDate();
        y.push(aa);
      }

      var total = y.reduce((a, b) => a + b);

      for (let n = 1; n <= total + 1; n++) {
        var getDate = new Date(f, m, n);
        var today = new Date();
        let longDay = getDate.toLocaleString('en-us', { weekday: 'long' }).toUpperCase();
        if (this.state.days.includes(longDay) && getDate >= today) {
          var setDate = this.state.allDates.push(
            [longDay + ', ' + getDate.getDate() + ' ' + getDate.toLocaleString('default', { month: 'long' }) + ' ' + getDate.getFullYear()]
          );
        }
        this.setState({ setDate });
      }
    } else {
      return false;
    }
  }

  componentDidMount = async () => {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      this.setState({ auth_token: user.auth_token }, async () => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let paymentId = params.get('paymentId');
        if (paymentId) { this.createBookingMid(paymentId); }
        if (this.state.activityId) {
          const getActivity = await fetch('https://app.join.com.kw/api/v1/user/getActivityById', {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Lang': localStorage.getItem('Lang'),
              'auth-token': this.state.auth_token
            },
            body: JSON.stringify({
              club_activity_id: this.state.activityId
            })
          }),
            data = await getActivity.json();
            if (data.code === 1) {
          const child_data = data.payload.child_data;
          this.setState({ child_data });
          
          const child = data.payload.child;
          this.setState({ child });
          const getActivityById = data.payload.location;
          
          this.setState({ getActivityById });
          this.setState({mrp:getActivityById[0].price})
          const allActivityData = data.payload;
          this.setState({ allActivityData });
          }
          if (data.code === 3) {
              localStorage.removeItem('user');
              document.location.reload()
          } 
        }
      });

    }
    this.promos();
  }

  getBookingData = () => {
    const selectedData = this.state.dateTime;
    const ActivityData = selectedData.map(v => {
      let countchild = this.state.input_check_box.length;
      let booking_amount = v.price * countchild;
      this.setState({mrp:booking_amount});
      let discount = 0;
      console.log(this.state.promocodeDetails);
      if(this.state.promocodeDetails){        
        let isApplied = false;
        console.log(this.state.promocodeDetails.applied_on);
        if(this.state.promocodeDetails.applied_on == "all"){
          isApplied = true;
        }
        if(this.state.promocodeDetails.applied_on == "activity"){
          if(this.state.promocodeDetails.club_activity_id==this.state.allActivityData.id){
            isApplied = true;
          }          
        }
        if(this.state.promocodeDetails.applied_on == "club"){
          if(this.state.promocodeDetails.club_activity_id==this.state.allActivityData.club_id){
            isApplied = true;
          }          
        }
        if(isApplied==true && this.state.promocodeDetails.min_seat<=countchild){
          if(this.state.promocodeDetails.type=="Percentage"){
            discount = v.price * countchild * this.state.promocodeDetails.value / 100;
            booking_amount = v.price * countchild - discount;
          }
          if(this.state.promocodeDetails.type=="Fixed"){ 
            if(this.state.promocodeDetails.min_seat > 1){
              discount = countchild * this.state.promocodeDetails.value;
            } else {
              discount = this.state.promocodeDetails.value;
            }
            booking_amount = v.price * countchild - discount;
          }
        }

      }
      this.setState({total:booking_amount});
      this.setState({discount:discount});
      return {
        frequency: v.frequency,
        booking_amount: booking_amount,
        days_for_activity: v.days_for_activity,
        branch_id: v.branch.id,
        max_seats: v.max_seats,
        booking_discount: discount,
        booking_amount_type: "",
        booking_status: "SUCCESS",
        selected_date: this.state.select_date,
        activity_id: this.state.getActivityById[0].club_activity_id,
        booking_payment:  booking_amount
      }
    });

    const getChildData = this.state.input_check_box.map(v=>{
      return {
        branch_id: this.state.dateTime[0].id,
        activity_id: this.state.getActivityById[0].club_activity_id,
        child_id: v,
      }
    });

    const BookingSession = this.state.input_check_box.map(v=>{
      return {
      branch_id: this.state.dateTime[0].id,
      no_of_session: 1,
      to_time: this.state.times[0].to_time,
      from_time: this.state.times[0].from_time,
      club_activity_location_id: this.state.times[0].id,
      activity_id: this.state.getActivityById[0].club_activity_id,
      child_id: v
    }
  });

    this.setState({ ActivityData, getChildData, BookingSession });
  }

  createBookingPre = async () => {
    if (this.state.dateTime[0].price_type != 'PRICE') {
      this.createBooking();
    } else {
      let ad = JSON.stringify(this.state.ActivityData);
      let bs = JSON.stringify(this.state.BookingSession);
      let ci = JSON.stringify(this.state.getChildData);
      let dt = JSON.stringify(this.state.dateTime);
      localStorage.setItem("ad", ad);
      localStorage.setItem("bs", bs);
      localStorage.setItem("ci", ci);
      localStorage.setItem("dt", dt);
      console.log(JSON.parse(localStorage.getItem("activityDataStore")));
      var getToken = await fetch('https://app.join.com.kw/api/v1/user/fatura_token', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'auth-token': this.state.auth_token
        },
        body: JSON.stringify({
          pay_amount: this.state.ActivityData[0].booking_amount
        })
      });
      let data = await getToken.json();
      window.location.href = data.payload.RedirectUrl;
    }
  }

  createBookingMid = async (paymentId) => {
    var getToken = await fetch('https://app.join.com.kw/api/v1/user/fatura_payStatus', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'auth-token': this.state.auth_token
      },
      body: JSON.stringify({
        paymentId: paymentId
      })
    });
    let data = await getToken.json();
    if (data.payload.TransactionStatus == 2) {
      //show error
      let adp = JSON.parse(localStorage.getItem("ad"));
      let bsp = JSON.parse(localStorage.getItem("bs"));
      let cip = JSON.parse(localStorage.getItem("ci"));
      let dtp = JSON.parse(localStorage.getItem("dt"));
      this.setState({ ActivityData: adp });
      this.setState({ BookingSession: bsp });
      this.setState({ getChildData: cip });
      this.setState({ dateTime: dtp });
      this.createBooking();

    } else {
      console.error(data.payload.Error);
    }
  }


  createBooking = async () => {
    let ad = JSON.stringify(this.state.ActivityData);
    let bs = JSON.stringify(this.state.BookingSession);
    let ci = JSON.stringify(this.state.getChildData);
    fetch('https://app.join.com.kw/api/v1/user/createBooking', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Lang': localStorage.getItem('Lang'),
        'auth-token': this.state.auth_token
      },
      body: JSON.stringify({
        activity_data: ad,
        booking_txn: 'test_12345',
        child_id: ci,
        payment_method: this.state.dateTime[0].price_type,
        total: this.state.ActivityData[0].booking_amount,
        booking_session: bs
      })
    }).then(results => {
      return results.json();
    }).then(data => {
      this.setState({showBookModal: false});
      window.scrollTo(0, 0);
      if (data.code === 1) {
        document.getElementById('flshMsg').innerHTML = '<div class="alert alert-success" role="alert">' + data.message + '</div>';
        

      } else {
        document.getElementById('flshMsg').innerHTML = '<div class="alert alert-danger" role="alert">' + data.message + '</div>';
      }
      setTimeout(function () { document.getElementById('flshMsg').innerHTML = ''; let url = window.location.href;
      window.location.href = url.split('?')[0]; }, 5000);
    }).catch(err => console.error(err));
  }

  selectOptionHandler = (e) => {
    this.setState({ [e.target.id]: e.target.value });
    setTimeout(() => { this.getBookingData(); }, 100);
  }

  promos = async () => {
    await fetch("https://app.join.com.kw/api/v1/promoCode", {
      method: "get",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Lang': localStorage.getItem('Lang'),
      },
    }).then((response) => {
      return response.json();
    }).then((res) => { 
      if (res.code === 1) {
        this.setState({ promoList: res.payload })
      }
    }).catch(err => {
      console.error(err);
    });
  }

  applyPromocode = (e)=>{
    console.log(this.state.ActivityData);
    
    let promoCode = this.state.promocode;
    const promoList = this.state.promoList;
    const promocodeDetails = promoList.map(v => {
      if(v.code==promoCode)
      this.setState({ promocodeDetails: v })
    });
    setTimeout(() => { this.getBookingData(); }, 200);
  }

  selectLocation = (e) => {
    const getDateTime = [...this.state.dateTime] = [this.state.getActivityById[e.target.value]];
    this.setState({
      [e.target.id]: e.target.value,
      getDateTime,
      from_d: this.state.dateTime[0].from_date,
      to_d: this.state.dateTime[0].to_date,
      days: this.state.dateTime[0].days_for_activity,
      times: this.state.dateTime[0].location_session
    });

    document.getElementById('select_date').selectedIndex = 0;
    document.getElementById('select_time').selectedIndex = 0;

    setTimeout(() => { this.getDateFrom_to(); this.getBookingData(); }, 100);
  }

  render() {
    return (
      <div>
        <Modal show={this.props.showBookModal} onHide={this.props.modalHandler}>
          <Modal.Header closeButton>
            <Modal.Title className="m-0">{this.state.allActivityData.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="select_fields">
              <div className="select_f d-flex align-items-center mb-3">
                <div className="icon-map-pin pr-2"></div>
                <FormControl id="select_location" as="select" defaultValue="select location" onChange={this.selectLocation}>
                  <option value="select location" disabled={true}>Select Location</option>
                  {
                    this.state.getActivityById.map((vn, In) => {
                      return <option value={In} key={vn.branch.id}>{vn.branch.branch_name}</option>
                    })
                  }
                </FormControl>
              </div>
              <div className="select_f d-flex align-items-center mb-3">
                <div className="icon-calendar pr-2"></div>
                <FormControl as="select" defaultValue="Select Date" id="select_date" onChange={this.selectOptionHandler}>
                  <option value="Select Date" disabled={true}>Select Date</option>
                  {
                    this.state.allDates.map((dates, index) => {
                      return <option value={dates} key={index}>{dates}</option>
                    })
                  }
                </FormControl>
              </div>
              <div className="select_f d-flex align-items-center mb-3">
                <div className="icon-clock2 pr-2"></div>
                <FormControl as="select" defaultValue="Select Time" id="select_time" onChange={this.selectOptionHandler}>
                  <option value="Select Time" disabled={true}>Select Time</option>
                  {
                    this.state.times.map((time, timeIndex) => {
                      var from_time = time.from_time,
                        to_time = time.to_time,
                        fTime = from_time.slice(0, from_time.lastIndexOf(':')) + ' to ' + to_time.slice(0, to_time.lastIndexOf(':'));
                      return <option value={fTime} key={timeIndex}>{fTime}</option>
                    })
                  }
                </FormControl>
              </div>
              <div className="select_f d-flex align-items-center mb-3">
                <div className="icon-child pr-2"></div>
                <div className="Book_title">Select your Child</div>
                <div className="ml-auto add_child">
                  {/* <Button variant="primary" onClick={this.addChild}>Add</Button> */}
                </div>
              </div>

              {
                this.state.child_data.map((c, ci) => {
                  return this.state.child.map((cc, cci) => {
                    if (c == cc.child_id)
                      return <p key={cc.child_id} className="d-flex align-items-center">
                        {cc.child_name}
                        <input className="ml-auto child_input float-right" onChange={(e) => {
                          if (e.currentTarget.checked) {
                            
                            e.currentTarget.checked = true;
                            let joined = this.state.input_check_box.concat(e.currentTarget.value);
                            this.setState({ input_check_box: joined });
                            
                            setTimeout(() => { this.getBookingData(); }, 100);
                          } else {
                            let array = this.state.input_check_box; 
                            let index = array.indexOf(e.target.value)
                            if (index !== -1) {
                              array.splice(index, 1);
                              this.setState({input_check_box: array});
                              setTimeout(() => { this.getBookingData(); }, 100);
                            }
                          }
                        }} value={cc.child_id} type="checkbox" />
                      </p>
                  })
                })
              }
              {/* {
                this.state.book_options.map((v, i) => {
                  return <div key={i} className="getChild">
                    {
                      v.select.map((vv, ii) => {
                        return <FormControl className="mb-3" key={ii} as="select">
                          <option>{vv.name}</option>
                          {
                            vv.options.map((nv, ni) => {
                              return <option key={ni}>{nv.option}</option>
                            })
                          }
                        </FormControl>
                      })
                    }
                    <div className="remove_btn text-right">
                      <Button variant="danger" onClick={() => { this.removeChild(i) }}>Remove</Button>
                    </div>
                  </div>
                })
              } */}

              {/* end map dynamic select options */}
              <div><FormControl type="text" className="form-group width50 promocode" id="promocode" name="promocode" placeholder="Promo Code" value={this.state.fname}
                  onChange={e => this.setState({ promocode: e.target.value })} /><Button variant="primary" onClick={this.applyPromocode}>Apply</Button>
                  </div>
                  <div className="promocodeApplied">{this.state.discount? <strong>Coupon Applied </strong>:'No coupon is applied!'}</div>
            </div>
            
            <div className="totalprice">Total (KD): {this.state.mrp ? this.state.mrp :''} {this.state.discount? ' - '+this.state.discount+' = '+this.state.total : ''} </div>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <Button variant="primary" id="closebookmodal" onClick={this.props.modalHandler}>Close</Button>
            <Button variant="primary" onClick={this.createBookingPre}>Confirm</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
export default BookNow;