import React, { Component } from 'react';
import { Container, Row, Col, Form, FormControl } from 'react-bootstrap';

class Feedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fname: '',
      lname: '',
      email: '',
      message: '',
      activitiesList: [],
      options: []
    }

    let search = window.location.search;
    let params = new URLSearchParams(search);
    this.token = params.get('token');
    this.activitiesList = [];
    if (typeof (this.token) == 'undefined' || this.token == null) { this.token = ""; }
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.auth_token) { 
      if(this.token==""){
        this.token = user.auth_token;
      }
     }
  }

  componentDidMount = async () => {
    const activityList = await fetch('http://app.join.com.kw/api/v1/getActiveActivityList');
    const getJson = await activityList.json();
    this.setState({ options: getJson });
  }

  handleFormSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.target);
    fetch('http://app.join.com.kw/api/v1/addFeedback', {
      method: 'POST',
      headers: {
        //'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: data,
    }).then(results => {
      return results.json();
    }).then(data => {
      console.log(data.message);
      if (data.code === 1) {
        document.getElementById('flshMsg').innerHTML = '<div class="alert alert-success" role="alert">' + data.message + '</div>';
      } else {
        document.getElementById('flshMsg').innerHTML = '<div class="alert alert-danger" role="alert">' + data.message + '</div>';
        localStorage.removeItem('user');
        document.location = "feedback";
      }
      setTimeout(function () { document.getElementById('flshMsg').innerHTML = ''; }, 3000);
    });
  }




  render() {
    return (
      <div>
        <Container className="banner_sec">
          <Row>
            <Col sm={3}>&nbsp;</Col>
            <Col sm={6}>
              <h2>Feedback</h2>
              <div id="msg"></div>
              <Form action="#" className="contact_input_area" onSubmit={this.handleFormSubmit}>
                <FormControl type="text" className="form-group" id="fname" name="fname" placeholder="Your name.." value={this.state.fname}
                  onChange={e => this.setState({ fname: e.target.value })} />
                <FormControl type="email" className="form-control form-group" id="email" name="email" placeholder="Your email" value={this.state.email}
                  onChange={e => this.setState({ email: e.target.value })} />
                <FormControl as="select" name="activity_id">
                  {
                    this.state.options.map((v, i) => {
                      return <option value={v.value} key={i}>{v.label}</option>
                    })
                  }
                </FormControl>

                <FormControl as="textarea" id="message" className="form-group" rows="4" name="message" placeholder="Write something.." onChange={e => this.setState({ message: e.target.value })}
                  value={this.state.message} />

                <input type="hidden" name="token" value={this.token} />
                <FormControl type="submit" className="btn submit-btn" value="Submit" />
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Feedback;