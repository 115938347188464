import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from '../components/pages/home/home';
import MainHeader from '../components/headers/main_header';
import Feedback from '../components/pages/feedback/feedback';
import Activites from '../components/pages/activites/activites';
import Footer from '../components/footers/footer';
import ContactSec from '../components/contact-sec/contact-sec';

class Routers extends Component {
  
  middleMargin = () => {
   // var x = document.getElementById('main_footer').clientHeight;
   // document.getElementById('middle-sections').style.marginBottom = x + 'px';
  }

  componentWillMount() {
    setTimeout(() => {
      this.middleMargin();
    }, 500)
  }

  render() {
    return (
      <Router>
        <div>
          <MainHeader />
          <div id="middle-sections">
            <Switch>
              <Route exact path="/" component={Home}  />
              <Route path="/club" />
              <Route path="/activites/:id" component={Activites} />
              <Route path="/feedback" />
            </Switch>
          </div>
          <ContactSec />
          <Footer /> 
        </div>
      </Router>
    );
  }
}

export default Routers;