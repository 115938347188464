import React, {Component} from 'react';
import {Container, Carousel} from 'react-bootstrap';
import { Link,Route,  withRouter } from 'react-router-dom';
import BookNow from '../../book-now/book_now';
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css";

class Home extends Component{
    constructor(props){
        super(props);
        this.state={
          topPicksSlider:false,
          mostSellingSlider:false,
          promoPhotos: false,
          activityData: false,
          get_from_change: '',
          get_to_change: '',
          activity_type: 'MONTH',
          get_change_data: '',
          slider: false,
          activitiesAtLoad: true,
          filterPage: false,
          filteredData: false,
          age_from: false,
          age_to: false,
          interests_id: '',
          price_to:10000,
          price_from:0,
          price_type:false,
          location_id:false,
          club_id:false,
          club_name:'',
          areaList:false,
          clubList:false,
          date_data:false,
          startDate:false,
          resultCount: 0,
        }
    }

    componentDidMount = () => {
      this.areas();      
      this.topPicks();
      this.mostSelling();
      this.promoPhoto();
      this.activityData();
      this.HomeBannerOpen();
      this.clubs();
      if(this.props.location.state){
        console.log(this.props.location.state);
        if(this.props.location.state.club_id){
          this.setState({club_id:this.props.location.state.club_id});
          this.setState({ activitiesAtLoad: false });
          
          this.setState({ filterPage: true },()=>{ this.filteredActivity(); });
          setTimeout(()=>{ 
          if(this.state.clubList){
          this.state.clubList.map((v,i)=>{
            if(v.id == this.props.location.state.club_id){
              this.setState({club_name:v.name});
              //this.props.location.state.club_id = false;
              //history.replace('', null);
              

            }
          })
          this.props.history.replace('',null);
        }
      },3000);
        }
      }
    }

    areas = async () => {
      await fetch("https://app.join.com.kw/api/v1/areaList", {
        method: "get",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Lang': localStorage.getItem('Lang'),
        },
      }).then((response) => {
        return response.json();
      }).then((res) => { 
        if (res.code === 1) {
          this.setState({ areaList: res.payload })
        }
      }).catch(err => {
        console.error(err);
      });
    }

    clubs = async () => {
      await fetch("https://app.join.com.kw/api/v1/getClubListWeb", {
        method: "post",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Lang': localStorage.getItem('Lang'),
        },
      }).then((response) => {
        return response.json();
      }).then((res) => { 
        if (res.code === 1) {
          this.setState({ clubList: res.payload })
        }
      }).catch(err => {
        console.error(err);
      });
    }

    topPicks = async () => {
        await fetch("https://app.join.com.kw/api/v1/topPicks", {
          method: "get",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Lang': localStorage.getItem('Lang'),
          },
        }).then((response) => {
          return response.json();
        }).then((res) => { 
          if (res.code === 1) {
            this.setState({ topPicksSlider: res.payload })
          }
        }).catch(err => {
          console.error(err);
        });
      }

      mostSelling = async () => {
        await fetch("https://app.join.com.kw/api/v1/mostSelling", {
          method: "get",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Lang': localStorage.getItem('Lang'),
          },
        }).then((response) => {
          return response.json();
        }).then((res) => { 
          if (res.code === 1) {
            this.setState({ mostSellingSlider: res.payload })
          }
        }).catch(err => {
          console.error(err);
        });
      }

      PostSingleDate = async (get_date_type) => {
        fetch("https://app.join.com.kw/api/v1/user/getActivityList", {
          method: "post",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Lang': localStorage.getItem('Lang'),            
          },
          //make sure to serialize your JSON body
          body: JSON.stringify({
            activity_type: 'DATE',
            date_data: get_date_type
          })
        }).then(res => res.json())
        .then((result) => {
          if (result.code === 1) {
            this.setState({ activityData: result.payload });
          }          
        }).catch((err) => {
          console.error(err);
        });
      }

      activityData = async () => {
        fetch("https://app.join.com.kw/api/v1/getActivityListWeb", {
          method: "post",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Lang': localStorage.getItem('Lang'),
          },
          //make sure to serialize your JSON body
          body: JSON.stringify({
            activity_type: this.state.activity_type,
            from_date: this.state.get_from_change,
            to_date: this.state.get_to_change,
            date_data: this.state.date_data
          })
        }).then(res => res.json())
          .then((result) => {
            if (result.code === 1) {
              this.setState({ activityData: result.payload });;
            }
            if (result.code === 3) {
              localStorage.removeItem('user');
             //setTimeout(()=>{ document.location.reload(); },3000);
            }
          }).catch((err) => {
            console.error(err);
          });
      }

      monthActivityList = (e)=> {
        document.getElementById(e.target.id).classList.add("active");
        document.getElementById('weektab').className = "nav-link";   
        document.getElementById('datetab').className = "nav-link";
        const Today = new Date;
        const from_date = new Date(Today.getFullYear(), Today.getMonth() + 2 , 0),
        to_date = new Date(Today.getFullYear(), Today.getMonth() + 2 , 0);
        from_date.setDate(1);
        const get_from_change = from_date.getFullYear() + '-' + from_date.getMonth() + '-' + from_date.getDate();
        const get_to_change = to_date.getFullYear() + '-' + to_date.getMonth() + '-' + to_date.getDate();
        this.setState({ activity_type: 'MONTH' });
        this.setState({ get_from_change: get_from_change });
        this.setState({ get_to_change: get_to_change },()=>{ this.activityData(); });
        console.log(get_from_change);
        
      }

      weekActivityList = (e)=> {
        document.getElementById(e.target.id).classList.add("active");
        document.getElementById('monthtab').className = "nav-link";   
        document.getElementById('datetab').className = "nav-link";         
        const from_date = new Date(),
        to_date = new Date();
        to_date.setDate(to_date.getDate() + 7);
        let fm = from_date.getMonth() + 1;
        let tm = to_date.getMonth() + 1;

        const get_from_change = from_date.getFullYear() + '-' + fm + '-' + from_date.getDate();
        const get_to_change = to_date.getFullYear() + '-' + tm + '-' + to_date.getDate();
        this.setState({ activity_type: 'WEEK' });
        this.setState({ get_from_change: get_from_change });
        this.setState({ get_to_change: get_to_change },()=>{ this.activityData(); });
        console.log(get_from_change);
        
      }

      filter1 = (e)=> {              
        e.preventDefault();
        document.getElementsByTagName("header")[0].classList.add('bgImageHeader');
        let int1 = document.getElementById('interests_id').value;
        let age1 = document.getElementById('agegroup').value;
        let area1 = document.getElementById('location_id').value;

        this.setState({interests_id: int1} );

        if(age1==1){
          this.setState({age_from: '1'});
          this.setState({age_to: '5'})
        }
        if(age1==2){
          this.setState({age_from: '5'});
          this.setState({age_to: '10'})
        }
        if(age1==3){
          this.setState({age_from: '10'});
          this.setState({age_to: '15'})
        }
        if(age1==4){
          this.setState({age_from: '15'});
          this.setState({age_to: '30'} )
        }
        this.setState({location_id: area1} );
        this.setState({ activitiesAtLoad: false });
        this.setState({ filterPage: true },()=>{ this.filteredActivity(); });
        
        
      }

      HomeBannerOpen = async () => {
        await fetch("https://app.join.com.kw/api/v1/promoBannerOpen", {
          method: "post",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Lang': localStorage.getItem('Lang'),
          },
        }).then((response) => {
          return response.json();
        }).then((res) => {
          this.setState({ slider: res.payload })
        }).catch(err => {
          console.error(err);
        });
      }
      
      promoPhoto = async () => {
        await fetch("https://app.join.com.kw/api/v1/promoPhotoWeb", {
          method: "post",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Lang': localStorage.getItem('Lang'),
            
          },
        }).then((response) => {
          return response.json();
        }).then((res) => { 
          if (res.code === 1) {
            this.setState({ promoPhotos: res.payload })
          }
        }).catch(err => {
          console.error(err);
        });
      }

      filteredActivity = async () => {
        //window.scrollTo(0, 0);
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
        fetch("https://app.join.com.kw/api/v1/getFiltersWeb", {
          method: "post",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Lang': localStorage.getItem('Lang'),
          },
          //make sure to serialize your JSON body
          body: JSON.stringify({
            interests_id: this.state.interests_id,
            age_from: this.state.age_from,
            age_to: this.state.age_to,
            price_from: this.state.price_from,
            price_to: this.state.price_to,
            price_type: this.state.price_type,
            club_id:this.state.club_id,
            area_id:this.state.location_id,
            from_date: this.state.get_from_change,
            to_date: this.state.get_to_change,
            date_data: this.state.date_data,
          })
        }).then(res => res.json())
          .then((result) => {
            if (result.code === 1) {
              this.setState({ filteredData: result.payload });
              this.setState({resultCount: result.payload.length});
            }
            if (result.code === 3) {
              localStorage.removeItem('user');
             //setTimeout(()=>{ document.location.reload(); },3000);
            }
          }).catch((err) => {
            console.error(err);
          });
      }

      interestFilter = (e)=>{
        let v = e.target.value;
        let ids = this.state.interests_id;       
        
          if(ids){
            let idsArray = ids.split(',');
            let i = idsArray.findIndex(k => k==v);
            if(e.target.checked){            
            if(i == -1){
              ids = ids +','+v;
            }
          } else {
            if(i != -1){ 
              idsArray.splice(i,1);
              ids = idsArray.toString();
            }
          }
          
          } else {
            if(e.target.checked){ ids= v; }          }
            
            this.setState({ interests_id: ids },()=>{ this.filteredActivity(); });
            console.log(ids);
      }

      agegroup = (e)=>{
        if(e.target.className=="active"){
          this.setState({age_from: false});
          this.setState({age_to: false},()=>{ this.filteredActivity(); });
          e.target.classList.toggle("active");
        } else {
        if (document.querySelector('.ageroup .list-unstyled span.active') !== null) {
          document.querySelector('.ageroup .list-unstyled span.active').classList.remove('active');
        }
        e.target.classList.add("active");
        if(e.target.dataset.pg==1){
          this.setState({age_from: '1'});
          this.setState({age_to: '5'},()=>{ this.filteredActivity(); })
        }
        if(e.target.dataset.pg==2){
          this.setState({age_from: '5'});
          this.setState({age_to: '10'},()=>{ this.filteredActivity(); })
        }
        if(e.target.dataset.pg==3){
          this.setState({age_from: '10'});
          this.setState({age_to: '15'},()=>{ this.filteredActivity(); })
        }
        if(e.target.dataset.pg==4){
          this.setState({age_from: '15'});
          this.setState({age_to: '30'},()=>{ this.filteredActivity(); })
        }
      }

        console.log(e.target.dataset.pg);
      }

      filterbyCurrentMonth = ()=> {
        const Today = new Date;
        const from_date = new Date(Today.getFullYear(), Today.getMonth() + 2 , 0),
        to_date = new Date(Today.getFullYear(), Today.getMonth() + 2 , 0);
        from_date.setDate(1);
        const get_from_change = from_date.getFullYear() + '-' + from_date.getMonth() + '-' + from_date.getDate();
        const get_to_change = to_date.getFullYear() + '-' + to_date.getMonth() + '-' + to_date.getDate();
        this.setState({ activity_type: 'MONTH' });
        this.setState({ get_from_change: get_from_change });
        this.setState({ get_to_change: get_to_change },()=>{ this.filteredActivity(); });
        console.log(get_from_change);
        
      }

      filterbyCurrentWeek = ()=> {
              
        const from_date = new Date(),
        to_date = new Date();
        to_date.setDate(to_date.getDate() + 7);
        let fm = from_date.getMonth() + 1;
        let tm = to_date.getMonth() + 1;

        const get_from_change = from_date.getFullYear() + '-' + fm + '-' + from_date.getDate();
        const get_to_change = to_date.getFullYear() + '-' + tm + '-' + to_date.getDate();
        this.setState({ activity_type: 'WEEK' });
        this.setState({ get_from_change: get_from_change });
        this.setState({ get_to_change: get_to_change },()=>{ this.filteredActivity(); });
        console.log(get_from_change);
        
      }

      priceRange = (e)=>{
        if(document.getElementById('free').checked){
          this.setState({price_to: '0'});          
        }
        if(document.getElementById('kd1').checked){
          this.setState({price_to: '10'});          
        }
        if(document.getElementById('kd10').checked){
          this.setState({price_to: '50'});          
        }
        if(document.getElementById('kd50').checked){
          this.setState({price_to: '1000'});
          this.setState({price_from: '50'}); 
        }
        if(document.getElementById('kd10').checked){
          this.setState({price_from: '10'});          
        }
        if(document.getElementById('kd1').checked){
          this.setState({price_from: '1'});  
        }
        if(document.getElementById('free').checked){
          this.setState({price_from: '0'});          
        }
        setTimeout(()=>{ this.filteredActivity(); },1000);

        

        
      }

      payModeChange = (e)=>{
        this.setState({price_type: e.target.value },()=>{ this.filteredActivity(); })
      }
      clubIdChange = (e)=>{
        this.setState({club_name:''});
        this.setState({club_id: e.target.value },()=>{ this.filteredActivity(); })
      }
      locationIdChange = (e)=>{
        this.setState({location_id: e.target.value },()=>{ this.filteredActivity(); })
      }
      selectDate = date => {
        document.getElementById('datetab').classList.add("active");
        document.getElementById('monthtab').className = "nav-link";   
        document.getElementById('weektab').className = "nav-link";
        var day = date.getDate();
      var monthIndex = date.getMonth();
      var year = date.getFullYear();

      let date1 = year + '-' + monthIndex + '-' + day;
        console.log(date1);
        this.setState({ activity_type: 'DATE' });
        this.setState({ date_data : date1 });
        setTimeout(()=>{ this.activityData(); },1000);
        
      }

      selectDateFilter = date => {
        
      var day = date.getDate();
      var monthIndex = date.getMonth();
      var year = date.getFullYear();

      let date1 = year + '-' + monthIndex + '-' + day;
        console.log(date1);
        this.setState({ activity_type: 'DATE' });
        this.setState({ date_data : date1 });
        setTimeout(()=>{ this.filteredActivity(); },1000);
        
      }

     
    

    render(){
      var h2style = {
        'fontWeight': '300', 'color': '#0ec9bc', 'fontSize':'25', 'width': '100%'
      };
    return(
        <div ref="homeref">
          {this.state.activitiesAtLoad ?
          <div>
          <div className="">
            <div className="">
              <div className="banner">
                <div className="banner-innner">
                  <div className="container">
                    <div className="row">         
                      <div className="col-md-6">
                        <div className="banner-left text-center">
                          <img src="img/bg-img/logo2.png" />
                        </div>
                      </div>            
                      <div className="col-md-6">
                        <div className="banner-right">
                          <form action="" method="">
                            <div className="form-group row">
                              <label for="inputPassword" className="col-sm-3 col-form-label text-white">Select Interest</label>
                              <div className="col-sm-9">
                              <select name="interests_id" id="interests_id" className="form-control rounded">
                                <option value="">Select Interest</option>
                                <option value="1">Sports</option>
                                <option value="2">Art</option>
                                <option value="3">Dance & Theatre</option>
                                <option value="4">Music & Singing</option>
                                <option value="5">Education</option>
                                <option value="6">Trips & events</option>
                                <option value="7">Hobbies & Others</option>
                                <option value="8">Sesonal Camps</option>
                                <option value="56">Charitable Events & Workshop</option>
                                <option value="57">Cooking & Baking</option>
                                <option value="65">Nurseries & Child Care</option>
                                <option value="66">Special Needs</option>
                                <option value="78">Fun at Home</option>
                                <option value="79">Online</option>
                              </select>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label for="inputPassword" className="col-sm-3 col-form-label text-white">Age Group</label>
                              <div className="col-sm-9">
                              <select id="agegroup" className="form-control rounded">
                                <option value="">Select</option>
                                <option value="1">0-5</option>
                                <option value="2">6-10</option>
                                <option value="3">11-15</option>
                                <option value="4">above 15</option>
                              </select>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label for="inputPassword" className="col-sm-3 col-form-label text-white">Location</label>
                              <div className="col-sm-9">
                              <select id="location_id" className="form-control rounded">
                                <option value="">Location</option>
                                {this.state.areaList ? this.state.areaList.map((v,i)=>{ 
                                  return (<option value={v.id}>{v.gov}</option> )
                                }) :'' }
                              </select>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label for="inputPassword" className="col-sm-3 col-form-label text-white"></label>
                              <div className="col-sm-9">
                              <button className="btn custom-btn" onClick={this.filter1}>Book Now</button>
                              </div>
                            </div>                   
                          </form>                
                        </div>
                      </div>            
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

<div className="app-screenshots-area ligh2t-bg section_padding_40 clearfix " >
    <div className="container">
      <div className="row">
      {this.state.slider ? this.state.slider.map((v,i)=>{
        if(i<3)
      return (
        <div className="col-md-4 p-1">
          <div className="galleryslide">
          <Link to={'/activites/'+v.activity_id} ><img src={v.photo_name} /></Link>
          </div>
        </div>
      )
    }
      ):<img className="center" src="img/loading.gif" />}
      </div>
    
</div>
</div>
<div className=" light-2bg mb-0" id="features" >
    <div className="container" >

        <ul className="nav nav-tabs nav-justified" role="tablist">
            <li className="nav-item">
                <a className="nav-link active" id="monthtab" onClick={this.monthActivityList} data-toggle="tab" href="#month">This Month</a>
            </li>
            <li className="nav-item">
                <a className="nav-link" id="weektab" onClick={this.weekActivityList} data-toggle="tab" href="#week">This Week</a>
            </li>
            <li className="nav-item">
            
            
                <a className="nav-link" id="datetab" href="javascript:void">
                  <DatePicker onChange={this.selectDate} customInput={<div>Pick Date</div>}/></a>
            </li>

        </ul>
        <div className="tab-content">
            <div className="tab-pane fade show active" id="communication">
                <div className="container">
                    <div className="row">
                    {this.state.activityData ? this.state.activityData.map((v,i)=>{
                      let style = { 'backgroundImage': 'url('+v.banner+')',};
                      return (
   
                        <div className="col-md-4 padding5 marginbot10" >
                           <div className="image-bg-1 t2" style={style}>
                            <div className="contentimage">
                                <div className="heading-liitle">
                                    <h5 >{v.title}</h5>
                                    <p >{v.location_count} | {v.age_from} - {v.age_to} years </p>
                                    <p ><label className="summercamp">{v.interests_name}</label>
                                        <label className="float-right">
                                            <span className="padding-right10">KWD {v.location[0].price}</span>
                                            
                                            <Link to={'/activites/'+v.id} className="btn btn-primary book">Book</Link></label></p>
                                        </div>
                                    </div>
                                </div>
                            </div>                           
                        )
                      }
                        ):<img className="center" src="img/loading.gif" />}
                    </div>
        </div>
    </div>

    <div className="tab-pane fade show " id="schedule">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                           <div className="image-bg-1">
                            <div className="contentimage">
                                <div className="heading-liitle">
                                    <h5 >Little Gym Summer Camp - Day Pass</h5>
                                    <p>No location Found | 1 - 12 years </p>
                                    <p ><label className="summercamp">Summer Camps</label>
                                        <label className="float-right">
                                            <span>KWD 235.000</span>
                                            <button type="button" className="btn btn-primary  book">Book</button></label></p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            

                    </div>
        </div>
    </div>



<div className="tab-pane fade">
    <div className="d-flex flex-column flex-lg-row">

        <div className="col-md-12">
           <div className="row mb-3 ">
              
            </div>
        </div>
</div>
</div>
<button type="button" onClick={this.filter1} className="btn btn-info float-right">View More</button>
</div>


</div>

</div>



<div className="app-screenshots-area light2-bg section_padding_0_100 clearfix pt-5" >
    <div className="container">
      <div className="row">

      <h2 style={h2style}>Top Picks</h2>
    {this.state.topPicksSlider ? this.state.topPicksSlider.map((v,i)=>{
      return (
        <div className="col-md-4 p-1">
        <div className="galleryslide">
        <Link to={'/activites/'+v.id} ><img src={v.banner} /><h2 className="innovative">{v.title}</h2></Link>
        </div>
      </div>
      )
    }
      ):<img className="center" src="img/loading.gif" />}
      
      <div className="clearfix"></div>
     
 </div>
 <button type="button" onClick={this.filter1} className="btn btn-info float-right">View More</button>
 </div>

</div>
<div className="app-screenshots-area light2-bg section_padding_0_100 clearfix " id="screenshot" >
    <div className="container">
        <div className="row">
            <div className="col-12 text-center" >
                <div className="bannerall">
                {this.state.promoPhotos ? 
          <Link to={'/activites/'+this.state.promoPhotos[1].activity_id} ><img height="300" src={this.state.promoPhotos[1].photo_name} className="img-fluid" alt="banner" /></Link>
            : <img className="center" src="img/loading.gif" />}
                </div>
            </div>
        </div>
    </div>

</div>
<div className="app-screenshots-area light2-bg section_padding_0_100 clearfix pt-5" >
    <div className="container">
      <div className="row">

      <h2 style={h2style}>Most Popular on Join</h2>
    {this.state.mostSellingSlider ? this.state.mostSellingSlider.map((v,i)=>{
      return (
        <div className="col-md-4 p-1">
        <div className="galleryslide">
        <Link to={'/activites/'+v.id} ><img src={v.banner} /><h2 className="innovative">{v.title}</h2></Link>
        </div>
      </div>
      )
    }
      ):<img className="center" src="img/loading.gif" />}
      
      <div className="clearfix"></div>
     
 </div>
 <button type="button" onClick={this.filter1} className="btn btn-info float-right">View More</button>
 </div>

</div>
</div>
:<div className="commonFilter section_padding_0_100">


<div className="app-screenshots-area ligh2t-bg section_padding_0_100 clearfix " >
<div className="container">
  <div className="row">
    <div className="col-md-3 padding0" >

      <div className="col-md-12">
      <h3 className="text-info">Interests</h3>
            <ul className="list-unstyled">
              <li><hr /></li>
              <li><label><input type="checkbox" onChange={this.interestFilter} defaultChecked={(this.state.interests_id=='1')?true:false} value="1" name="casual_dating" /> Sports</label></li>
              <li><label><input type="checkbox" onChange={this.interestFilter} defaultChecked={(this.state.interests_id=='2')?true:false} value="2" name="serious_commitment" /> Art</label></li>
              <li><label><input type="checkbox" onChange={this.interestFilter} defaultChecked={(this.state.interests_id=='3')?true:false} value="3" name="companionship" /> Dance & Theatre</label></li>
              <li><label><input type="checkbox" onChange={this.interestFilter} defaultChecked={(this.state.interests_id=='4')?true:false} value="4" name="hookups"/> Music & Singing</label></li>
              <li><label><input type="checkbox" onChange={this.interestFilter} defaultChecked={(this.state.interests_id=='5')?true:false} value="5" name="marriage"/> Education</label></li>
              <li><label><input type="checkbox" onChange={this.interestFilter} defaultChecked={(this.state.interests_id=='6')?true:false} value="6" name="local_connections"/> Trips & events</label></li>
              <li><label><input type="checkbox" onChange={this.interestFilter} defaultChecked={(this.state.interests_id=='7')?true:false} value="7" name="more_dates"/> Hobbies & Others</label></li>
              <li><label><input type="checkbox" onChange={this.interestFilter} defaultChecked={(this.state.interests_id=='8')?true:false} value="8" name="fast_matches" /> Sesonal Camps</label></li>
              <li><label><input type="checkbox" onChange={this.interestFilter} defaultChecked={(this.state.interests_id=='56')?true:false} value="56" name="online_interaction"/>Charitable Events & Workshop</label></li>
              <li><label><input type="checkbox" onChange={this.interestFilter} defaultChecked={(this.state.interests_id=='57')?true:false} value="57" name="high_compatiblity"/> Cooking & Baking</label></li>
              <li><label><input type="checkbox" onChange={this.interestFilter} defaultChecked={(this.state.interests_id=='65')?true:false} value="65" name="high_compatiblity"/> Nurseries & Child Care</label></li>
              <li><label><input type="checkbox" onChange={this.interestFilter} defaultChecked={(this.state.interests_id=='66')?true:false} value="66" name="high_compatiblity"/> Special Needs</label></li>
              <li><label><input type="checkbox" onChange={this.interestFilter} defaultChecked={(this.state.interests_id=='78')?true:false} value="78" name="high_compatiblity"/> Fun at Home</label></li>
              <li><label><input type="checkbox" onChange={this.interestFilter} defaultChecked={(this.state.interests_id=='79')?true:false} value="79" name="high_compatiblity"/> Online</label></li>
            </ul>
      </div>

      <div className="col-md-12 mt-4">
        <form action="" method="">
                   <div className="form-group">                   
                     <select id="location_id1" onChange={this.locationIdChange} className="form-control">
                      <option value="">Location</option>
                      {this.state.areaList ? this.state.areaList.map((v,i)=>{ 
                        return (<option value={v.id}>{v.gov}</option> )
                       }) :'' }
                     </select>
                    </div>
                </form>
      </div>


      <div className="col-md-12 mt-4">
        <div className="ageroup">
          <h3 className="text-info">Age</h3>
                <ul className="list-unstyled">
                  <li><hr /></li>
                  <li><span onClick={this.agegroup} data-pg="1"> 1-5</span></li>
                  <li><span onClick={this.agegroup} data-pg="2">6-10</span></li>
                  <li><span onClick={this.agegroup} data-pg="3"> 10-15</span></li>
                  <li><span onClick={this.agegroup} data-pg="4"> Older Than 15</span></li>
                </ul>
            </div>
      </div>
      <div className="clearfix"></div>

      <div className="col-md-12 mt-4">
      <h3 className="text-info">Date</h3>
      <form action="" method="">
            <ul className="list-unstyled">
              <li><hr /></li>
              <li><label><input type="radio" onChange={this.filterbyCurrentMonth} name="age" value="30"/>This Month</label></li>
              <li><label><input type="radio" onChange={this.filterbyCurrentWeek} name="age" value="30"/>This Week</label></li>
              <li><label><a href="javascript:void" className="dateAclass" >
                <DatePicker onChange={this.selectDateFilter} customInput={<div>Pick Date</div>}/></a></label></li>
            </ul>
            </form>
      </div>

      <div className="col-md-12 mt-4">
      <h3 className="text-info">Price</h3>
            <ul className="list-unstyled">
              <li><hr /></li>
              <li><label><input type="checkbox" onChange={this.priceRange} id="free" name="casual_dating"/> Free</label></li>
              <li><label><input type="checkbox" onChange={this.priceRange} id="kd1" name="serious_commitment"/> 1-10 KD</label></li>
              <li><label><input type="checkbox" onChange={this.priceRange} id="kd10" name="companionship"/> 10-50 KD</label></li>
              <li><label><input type="checkbox" onChange={this.priceRange} id="kd50" name="hookups"/> 50 KD Above</label></li>
            </ul>
      </div>

      <div className="col-md-12 mt-4">
        <form action="" method="">
                   <div className="form-group">                   
                     <select id="club_id" onChange={this.clubIdChange} className="form-control">
                      <option value="">Clubs</option>
                      {this.state.clubList ? this.state.clubList.map((v,i)=>{ 
                        return (<option value={v.id} selected={this.state.club_id == v.id}>{v.name_en}</option> )
                       }) :'' }
                     </select>
                    </div>
                </form>
      </div>

     <div className="col-md-12 mt-4">
      <h3 className="text-info">Payment Mode</h3>
      <form action="" method="">
            <ul className="list-unstyled">
              <li><hr /></li>
              <li><label><input type="radio" onChange={this.payModeChange} name="paymode" value="PRICE"/>Online</label></li>
              <li><label><input type="radio" onChange={this.payModeChange} name="paymode" value="PAY_AT_PLACE"/>Pay at club</label></li>
              <li><label><input type="radio" onChange={this.payModeChange} name="paymode" value="FREE"/>Free</label></li>
            </ul>
            </form>
      </div>

      <div className="col-md-12 mt-4">
      
      </div>
      


    </div>





    <div className="col-md-9">

      <div className="col-md-12 ">
        <h3 className="text-info">What's on for kids {this.state.club_name ? '(' +this.state.club_name +')' :'' }</h3>
        <p>{this.state.resultCount} Activities</p>
        {this.state.filteredData ? this.state.filteredData.map((v,i)=>{
          let price = v.location[0].price;
          if(price=='0.000'){ price = 'Free'; }
          else { price = 'KWD '+price;}
      return (
          <div className="row mb-4 mt-3 div10kids" >
            <div className=" col-md-5"><img src={v.banner} className="img-fluid" /></div>
            <div className="col-md-6">
              <h5>{v.title} <label className="float-right"><img src={v.club.logo} width="66" className="img-fluid"/></label></h5>
              <p className="margin0">Club Name : {v.club.name}</p>
              <p className="margin0" >Age Group  : {v.age_from} - {v.age_to} years</p>
              <p className="margin0">Locations    : {v.location_count}   </p>
              <h5 className="mt-3"><label  className="labelInterest">{v.interests_name}</label><label className="float-right">
                <span className="labelInterestSpan">{price}</span>
              <Link to={'/activites/'+v.id} className="btn btn-primary book">Book Now</Link></label></h5>
            </div>
          </div>
      )}) :''}

          
          
        
      </div>
      
    </div>
  </div>
</div>
</div>
</div> }
<BookNow modalHandler={this.modalHandler} showBookModal={this.state.showBookModal} activityId={this.props.match.params.id} />
</div>
        );
    }
}
export default Home;