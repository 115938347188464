import React, { Component } from 'react';
import Logo from '../../images/logo.png';
import { Link } from 'react-router-dom';

class Footer extends Component {
  render() {
    return (
      <footer id="main_footer" className="text-center position-fixed">
        <img src={Logo} alt="logo" className="img-fluid" />
        <p><Link target="_blank" to="\www.instagram.com"><i className="fab fa-instagram"></i></Link></p>
        <p>© 2019 Join. All rights reserved.</p>
      </footer>
    );
  }
}

export default Footer;
