import React, { Component } from 'react';
import { Container, Row, Col, Form, FormControl } from 'react-bootstrap';

class ContactSec extends Component {
    sendContact = (e)=>{
        e.preventDefault();
        document.getElementById('contactMsg').innerHTML ='<div class="alert alert-success" role="alert">Thanks for contacting us</div>';
        let name = document.getElementById('name').value;
        let email = document.getElementById('email').value;
        let content = document.getElementById('content').value;
        fetch("https://app.join.com.kw/api/v1/contactUs", {
          method: "post",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Lang': localStorage.getItem('Lang'),            
          },
          //make sure to serialize your JSON body
          body: JSON.stringify({
            name: name,
            email: email,
            content: content
          })
        }).then(res => res.json())
        .then((result) => {
          if (result.code === 1) {
            document.getElementById('contactMsg').innerHTML = result.message;
          }          
        }).catch((err) => {
          console.error(err);
        });

        setTimeout(()=>{ document.getElementById('contactMsg').innerHTML =""; },5000)
    }
  render() {
    return (
      <div><div>
      <div class="special_description_area downloadSpecial" >
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="special_description_img">
                            <img src="https://booking.join.com.kw/img/bg-img/special4.png" alt=""/>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-5 ml-xl-auto">
                        <div class="special_description_content">
                            <h2>From Toddlers To Teens</h2>
                            <p>Join App caters to all ages ranging from infants to young adults. Allowing users to see all that is related to their children's needs and interests, whether they have one or more, big or small, ALL in one place !</p>
                            <div class="app-download-area">
                                <div class="app-download-btn wow fadeInUp" data-wow-delay="0.2s">
                                    
                                    <a href="https://play.google.com/store/apps/details?id=com.joinkw" target="_blank">
                                        <i class="fa fa-android"></i>
                                        <p class="mb-0"><span>available on</span> Google Store</p>
                                    </a>
                                </div>
                                <div class="app-download-btn wow fadeInDown" data-wow-delay="0.4s">
                                    
                                    <a href="https://apps.apple.com/us/app/join-%D8%AC%D9%88%D9%8A%D9%86/id1464036538?ls=1" target="_blank">
                                        <i class="fa fa-apple"></i>
                                        <p class="mb-0"><span>available on</span> Apple Store</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
<div class="footer-contact-area section_padding_100 clearfix" id="contact">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                
                <div class="section-heading">
                    <h2>Get in touch with us!</h2>
                    <div class="line-shape"></div>
                </div>
                <div class="footer-text">
                    <p>We'll keep you posted with special offers and new Club listings, reviews and much more!</p>
                </div>
                   
                    <div class="phone-text">
                        <p><span>Phone:</span> +965 9229 4872</p>
                    </div>
                    <div class="email-text">
                        <p><span>Email: </span><a href="mailto:support@join.com.kw" className="contactMail" target="_blank">support@join.com.kw</a></p>
                    </div>
                </div>
                <div class="col-md-6">
                   <form action="" method="post" onSubmit={this.sendContact}>
                    <div class="contact_from" id="formfocus">
                        <div id="contactMsg"></div>
                        <div class="contact_input_area">
                            <div class="row">
                                
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <input type="text" class="form-control" name="name" id="name" placeholder="Your Name" required />
                                    </div>
                                </div>
                                
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <input type="email" class="form-control" name="email" id="email" placeholder="Your E-mail" required />
                                    </div>
                                </div>
                                
                                <div class="col-12">
                                    <div class="form-group">
                                        
                                        <textarea name="content" class="form-control" id="content" cols="30" rows="4" placeholder="Your message *" required=""></textarea>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div id="mail-status"></div>
                                </div>
                                <div class="col-12">
                                    <button type="submit" class="btn submit-btn" >Send Now</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
      </div>
    );
  }
}

export default ContactSec;
