import React, { Component } from 'react';
import { Container,Modal, Button,FormControl,Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ContactSec from '../../contact-sec/contact-sec';
import BookNow from '../../book-now/book_now';
import '../../../css/icomoon/style.css';

class Activites extends Component {
  constructor(props) {
    super(props);
    this.state = {    
      
      activityDetails:false,
      showBookModal: false,
      showLogin1Modal:false,
      showLogin1:false
    }
    this.modalHandler = this.modalHandler.bind(this);
  }

  componentDidMount = async () => {
    document.getElementsByTagName("header")[0].classList.add('bgImageHeader');
    window.scrollTo({top: 50, left: 0, behavior: 'smooth' });
    const getActivity = await fetch('https://app.join.com.kw/api/v1/getActivityByIdWeb', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Lang': localStorage.getItem('Lang'),
       
      },
      body: JSON.stringify({
        club_activity_id: this.props.match.params.id
      })
    }),
      data = await getActivity.json();
      
      
      const activityDetails = data.payload;
      this.setState({activityDetails});
      console.log(this.state.activityDetails.club_details.name);

      let user = JSON.parse(localStorage.getItem('user'));

    if (user && user.auth_token) {
      this.setState({ showLogin1: false });
      
      console.log(user.fname);
    } else {
      
      this.setState({ showLogin1: true });
    }
  }

  modalHandler = () => {
    this.setState({ showBookModal: !this.state.showBookModal });
  }

  modalHandlerLogin = () => {
    this.setState({ showLogin1Modal: !this.state.showLogin1Modal });
  }
  login = (event)=> {
    event.preventDefault();
    window.scrollTo({top: 90, left: 0, behavior: 'smooth' });
    const data = new FormData(event.target);
    fetch('https://app.join.com.kw/api/v1/logIn', {
      method: 'POST',
      headers: {
        //'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: data,
    }).then(results => {
      return results.json();
    }).then(data => {
      console.log(data.message);
      if (data.code === 1) {
        document.getElementById('loginmsg').innerHTML = '<div class="alert alert-success" role="alert">' + data.message + '</div>';
        localStorage.setItem('user', JSON.stringify(data.payload));
        
        this.setState({ customer_name: data.payload.fname });
        this.setState({ logText: 'Logout' });
        const token = data.payload.auth_token;
        setTimeout(()=> { this.setState({ showLogin1Modal: false }); document.location.reload(); }, 2100);
        console.log(data.payload);
      } else {
        document.getElementById('loginmsg').innerHTML = '<div class="alert alert-danger" role="alert">' + data.message + '</div>';
      }
      setTimeout(()=> { document.getElementById('loginmsg').innerHTML = ''; }, 2200);
    });
  }

  scrolltoId = (e)=>{
    let id = e.target.id;
    document.querySelector('.nav-item.active').classList.remove('active');
    let liEle = e.target.closest('li').classList.add('active');

    let idtab = id + 'Tab';
    document.getElementById(idtab).scrollIntoView();
  }


  render() {
    
    return (
      
      <div>
        <Container className="short_banner app-screenshots-area ligh2t-bg section_padding_0_100 clearfix ">
            {this.state.activityDetails ? 
          <img height="400" width="100%" src={this.state.activityDetails.banner} className="img-fluid" alt="banner" />
            : ''}
        </Container>
        <Container className="getmyid">
        {this.state.activityDetails ? 
          <div><h2>{this.state.activityDetails.title}<label class="float-right">
            <Link to={{ pathname:'/', state: { club_id: this.state.activityDetails.club_id} }}><img width="66" src={this.state.activityDetails.club_details.logo} class="img-fluid" /></Link></label></h2>
          <p>Club Name: {this.state.activityDetails.club_details.name}</p>
          <p>{this.state.activityDetails.age_from} - {this.state.activityDetails.age_to} Years</p>
          <p>Locations: {this.state.activityDetails.location_count}</p>
          </div>
            : ''}
        </Container>
        
          <Container>
          
          </Container>
        <Container className="act-desc">
        {this.state.activityDetails ? 
          <div class="site-section1 site-section1aa">
          <div class="container containeraa" >
            <ul class="nav backnone nav-pills mb-3 menu tab-responsive float-left" id="pills-tab">
              <li class="nav-item active"><a class="nav-link"  href="javascript:void"><span id="Overview" onClick={this.scrolltoId}>Description</span></a></li>
              <li class="nav-item"><a class="nav-link"    href="javascript:void"><span id="schedule" onClick={this.scrolltoId}> Schedule</span></a></li>
              <li class="nav-item"><a class="nav-link"  href="javascript:void"><span id="photo" onClick={this.scrolltoId}>Gallery</span></a></li>
    
            </ul>
            {this.state.showLogin1 ? <button onClick={this.modalHandlerLogin} class="btn btn-info bigbutton float-right">Book Now</button> :
            <button onClick={this.modalHandler} class="btn btn-info bigbutton float-right">Book Now</button> }
    
            <div class="tab-content" id="pills-tabContent">
              <div class="tab-pane fade show active clearfix" id="OverviewTab" >
    
                <div class="clearfix"></div>
    
                <div dangerouslySetInnerHTML={{__html:this.state.activityDetails.description}} class="section-heading-tabs text-left section-headaa">
    
                  
                </div>  
    
                
    </div>
    
    <div class="section-heading-tabs text-left mt-3 section-tabaa" id="scheduleTab">
      <div dangerouslySetInnerHTML={{__html:this.state.activityDetails.schedule}} class="tab-pane fa2de show active mt-0" id="photoTab" >
                  
                </div>
    
    </div>
    
    <div class="tab-pane fa2de show active mt-0" id="photoTab" >
      <div class="album  ">
       <div class="app-screenshots-area light2-bg section_padding_0_100 clearfix pt-5">
        <div class="container">
          <div class="row">
    
          <h2 className="photoaa">Photos</h2>
          {this.state.activityDetails ? this.state.activityDetails.gallery.map((v,i)=>{
            return (
          <div class=" p-1 imggallery" >
            <div class="galleryslide">
             <img src={v.photo_name} height="103" width="103"/>
            </div>
          </div>
          )
          }
          ):<img className="center" src="img/loading.gif" />}
          
    
           
    
          <div class="clearfix"></div>
         
     </div>
     
     </div>
    
    </div>
    </div>
    
    </div>
    
    </div>
    </div>
    </div>
    
          
            : ''}
        </Container>
        <Modal show={this.state.showLogin1Modal} onHide={this.props.modalHandler}>
      <Form action="#" className="contact_input_area" onSubmit={this.login}>
                
        <Modal.Header closeButton>
          <Modal.Title className="m-0">Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="loginmsg"></div>
        <FormControl type="text" className="form-group" id="fname" name="mobile" placeholder="Your Mobile.." value={this.state.mobile}
                  onChange={e => this.setState({ mobile: e.target.value })} />
                <FormControl type="password" className="form-control form-group" id="email" name="password" placeholder="Your password" value={this.state.password}
                  onChange={e => this.setState({ password: e.target.value })} />
          
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          
        <FormControl type="submit" className="btn submit-btn" value="Login" />
        </Modal.Footer>
        </Form>
      </Modal>

        <BookNow modalHandler={this.modalHandler} showBookModal={this.state.showBookModal} activityId={this.props.match.params.id} />
      </div>
    );
  }
}

export default Activites;
